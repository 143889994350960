import React from 'react'
import { Box, Text, Flex, Button, Accordion, Group, ActionIcon, Grid } from '@mantine/core'
import dayjs from 'dayjs'
import classes from './DetailSlug.module.css'
import { formatPrice } from '../../../../plugins/formatPrice'
import { useNavigate, useParams } from 'react-router-dom'
import { IconMap2 } from '@tabler/icons-react'
import { useDispatch } from 'react-redux'
import { update } from '../../../../store/cartData'
import { useLocalStorage } from '@mantine/hooks'
import { useSelector } from 'react-redux'

const defaultRoute = {
  key: 'route',
  defaultValue: ''
}

const MappingTypeEvent = ({ address, onlineLink, mapLink, dataEvent }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const params = useParams()
  const [, setRoute] = useLocalStorage(defaultRoute)
  const { isLogin } = useSelector(state => state.auth)


  const startRegister = (startRegister) => {
    const now = dayjs().unix()
    const expired = dayjs(startRegister).subtract(7, 'hours').unix()
    return now > expired
  }

  // const expiredRegister = (endRegister) => {
  //   const now = dayjs().unix()
  //   const expired = dayjs(endRegister).subtract(7, 'hours').unix()
  //   return now > expired
  // }

  const handleCheckout = (scheduleId, schedulePrice) => {
    const data = {
      transactionMethodId: null,
      eventScheduleIds: [ scheduleId ],
      eventSlug: params.slug,
      totalPrice: schedulePrice
    }
    dispatch(update({ 'cart': data }))
    if (!isLogin) {
      navigate("/login")
      setRoute('/checkout')
    } else {
      navigate("/checkout")
    }
  }

  const mappingButton = (valueSchedule) => {
    if (valueSchedule.status === 'registration_open') {
      if (!startRegister(valueSchedule.regisStart)) {
        return (<Button radius="md" px={31} color="green.4">Upcoming Event</Button>)
      }
      if (valueSchedule.status === 'registration_open') {
        return (<Button radius="md" px={31} onClick={() => handleCheckout(valueSchedule.id, valueSchedule.price)}>Daftar Sekarang</Button>)
      }
    } else {
      return (<Button radius="md" px={31} color="red">Pendaftaran Ditutup</Button>)
    }
  }

  if (address !== null) {
    const handleMapLink = (link) => {
      window.open(`${link}`, '_blank')
    }
    return (
      <Box>
        <Grid>
          <Grid.Col span={{ base: 12, lg: 8 }}>
            <Box align="left" mb={10}>
              <Text fz="md" fw="bold" mb="xs">Tipe: Offline</Text>
              <Text fz="sm" fw="normal" mb={8}>Tipe pembelajaran akan dilakukan secara offline. Untuk lokasi pembelajaran bisa di cek di alamat berikut ini:</Text>
              <Text fz="sm" fw="bold" mb={4}>Alamat: {address}</Text>
              <Group spacing="xs">
                <ActionIcon color="red">
                  <IconMap2 size="1.5rem" />
                </ActionIcon>
                <Text fz="sm">Klik <Text component='span' fw="bold" color='red' style={{ cursor: 'pointer' }} onClick={() => handleMapLink(mapLink)}>disini</Text> untuk menunjukkan peta lokasi</Text>
              </Group>
            </Box>
          </Grid.Col>
          <Grid.Col span={{ base: 12, lg: 4 }}>
            <Box align="right">
              {mappingButton(dataEvent)}
            </Box>
          </Grid.Col>
        </Grid>
      </Box>
    )
  }
  if (onlineLink !== null) {
    return (
      <Box>
        <Grid>
          <Grid.Col span={{ base: 12, lg: 8}}>
            <Box align="left">
              <Text fz="md" fw="bold" mb="xs">Tipe: Online</Text>
              <Text fz="sm" fw="normal">Tipe pembelajaran akan dilakukan secara online melalui platform online seperti Google Meet, Zoom, dll</Text>
            </Box>
          </Grid.Col>
          <Grid.Col span={{ base: 12, lg: 4}}>
            <Box align="right">
              {mappingButton(dataEvent)}
            </Box>
          </Grid.Col>
        </Grid>
      </Box>
    )
  }
}

const ScheduleSection = ({ schedules }) => {
  const isExpiredEvent = (endtimeEvent) => {
    const now = dayjs().unix()
    const expired = dayjs(endtimeEvent).subtract(7, 'hours').unix()
    return now > expired
  }

  const emptySchedules = () => {
    return (
      <Box p={16}>
        <Text align="center" fz="md" fw="bold">Event Ini Belum Memiliki Schedule</Text>
      </Box>
    )
  }

  const listSchedules = () => {
    return (
      <Accordion chevronPosition="left" variant="separated" mx="auto" styles={{ content: { paddingTop: '20px' } }}>
        {mappingSchedules}
      </Accordion>
    )
  }

  const mappingSchedules = schedules.map((val) => {
    const formatTime = (time) => {
      return time.slice(0, -3)
    }
    if (!isExpiredEvent(val.eventEnd)) { 
      return (
        <Accordion.Item value={val.id} key={val.name} style={{ border: '0.125rem solid #dee2e6', borderRadius: '10px', padding: '12px' }}>
          <Accordion.Control>
            <Grid>
              <Grid.Col span={{ base:12, lg:6 }}>
                <Box align="left">
                  <Text fz="md" fw="bold" mb={10}>{val.name}</Text>
                  <Box className={classes.dateSection}>
                    <Text fz="sm">Waktu Pelaksanaan: </Text>
                    <Text fz="sm" fw="bold">{formatTime(val.timeStart)} - {formatTime(val.timeEnd)}</Text>
                  </Box>
                  <Box className={classes.dateSection}>
                    <Text fz="sm">Registrasi Dimulai: </Text>
                    <Text fz="sm" fw="bold">{dayjs(val.regisStart).format('DD MMMM YYYY')} - {dayjs(val.regisEnd).format('DD MMMM YYYY')}</Text>
                  </Box>
                  <Box className={classes.dateSection}>
                    <Text fz="sm">Training Dimulai: </Text>
                    <Text fz="sm" fw="bold"> {dayjs(val.eventStart).format('DD MMMM YYYY')} - {dayjs(val.eventEnd).format('DD MMMM YYYY')}</Text>
                  </Box>
                </Box>
              </Grid.Col>
              <Grid.Col span={{ base: 12, lg:6 }}>
                <Box align="right">
                  <Text fz="xs" mb={14} c="dimmed" align="right">Quota Peserta : {val.quota}</Text>
                  <Text className={classes.priceSection} fw="bold" c="red">{formatPrice(val.price)}</Text>
                </Box>
              </Grid.Col>
            </Grid>
          </Accordion.Control>
          <Accordion.Panel style={{ borderTop: '1px solid #C1C2C5' }}>
            <Box>
              <MappingTypeEvent address={val.address} onlineLink={val.onlineLink} mapLink={val.mapLink} dataEvent={val} />
            </Box>
          </Accordion.Panel>
        </Accordion.Item>
      )
    } else {
      return (
        <Accordion.Item value={val.id} key={val.name} style={{ border: '0.125rem solid #dee2e6', borderRadius: '10px', padding: '12px' }}>
          <Accordion.Control disabled>
            <Box>
              <Flex justify="space-between" align="center">
                <Box>
                  <Text fz="md" fw="bold" mb={10} >{val.name}</Text>
                  <Text fz="sm" >Waktu Pelaksanaan: <Text component="span" fz="sm" fw="bold">{formatTime(val.timeStart)} - {formatTime(val.timeEnd)}</Text></Text>
                  <Text fz="sm" >Registrasi Dimulai: <Text component="span" fz="sm" fw="bold">{dayjs(val.regisStart).format('DD MMMM YYYY')} - {dayjs(val.regisEnd).format('DD MMMM YYYY')}</Text></Text>
                  <Text fz="sm" >Event Dimulai: <Text component="span" fz="sm" fw="bold"> {dayjs(val.eventStart).format('DD MMMM YYYY')} - {dayjs(val.eventEnd).format('DD MMMM YYYY')}</Text></Text>
                </Box>
                <Box>
                  <Text fz="xs" mb={18} color="dimmed" align="right">Quota Peserta : {val.quota}</Text>
                  <Button disabled px={36} radius="md">Event Berakhir</Button>
                </Box>
              </Flex>
            </Box>
          </Accordion.Control>
        </Accordion.Item>
      )
    }
  })

  return (
    <Box mb={35}>
      <Text fz="md" fw="bold">Biaya Pendaftaran</Text>
      <Box mt="md">
        {schedules.length > 0 ? listSchedules() : emptySchedules()}
      </Box>
    </Box>
  )
}

export default ScheduleSection