import React from 'react'
import { Box, Container, Grid, Text, Button } from '@mantine/core'
import HomeLogo from '../../../assets/img/HomeBackground.png'
import classes from '../../../pages/Home/Home.module.css'
import SEOPage from '../../seo/SEOPage'

const HeaderSection = ({ handleScroll }) => {
  return (
    <Box className={classes.headerSection} style={{ backgroundImage: `url(${HomeLogo}?dim=500x500)`}}>
      <SEOPage title='Laju Reaksi by Katalis Talenta Indonesia' desc='Penyedia Jasa Pelatihan dan Kompetensi Bidang Lingkungan' />
      <Container fluid className={classes.section}>
        <Box className={classes.innerHeader}>
          <Box className={classes.contentHeader}>
            <Grid justify="start" align="center">
              <Grid.Col span={{ base: 12, lg: 7 }}>
                <Box className={classes.paddingPage}> 
                  <Text className={classes.titleHeader} mb={16}>Penyedia Jasa Pelatihan dan Kompetensi Bidang Lingkungan</Text>
                  <Text className={classes.captionHeader} mb={24}>Bersama kami, optimalkan potensi sumber daya manusia dan lingkungan untuk masa depan yang lebih baik dan berkelanjutan</Text>
                  <Button className={classes.buttonHeader} fz={16} radius="md" onClick={handleScroll}>
                    <Text p={10} fw="bold">Mulai Sekarang</Text>
                  </Button>
                </Box>
              </Grid.Col>
            </Grid>
          </Box>
        </Box>
      </Container>
    </Box>
  )
}

export default HeaderSection