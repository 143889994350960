import React, { useEffect, useState } from 'react'
import PublicLayout from '../../layouts/PublicLayout'
import { getProgramList, getCategoryProgram } from '../../services/program'
import { Box, Button, Container, Grid, Select, Text, TextInput } from '@mantine/core'
import classes from './Learning.module.css'
import SkeletonCard from '../../components/ui/SekeletonCard'
import ProgramCard from '../../components/pages/Program/ProgramCard'
import { HelmetProvider } from 'react-helmet-async'
import SEOPage from '../../components/seo/SEOPage'

const defaultParameter = {
  skip: 0,
  take: 8,
  search: '',
  order: 'desc',
  kind: 'course',
  eventCategory: ''
}

const defaultOrder = [
  {
    label: 'Terbaru',
    value: 'desc'
  },
  {
    label: 'Terlama',
    value: 'asc'
  }
]

const LearningVideo = () => {
  const [params, setParams] = useState(defaultParameter)
  const [learningList, setLearningList] = useState([])
  const [categoryList, setCategoryList] = useState([])
  const [count, setCount] = useState(0)
  const [loading, setLoading] = useState(true)

  const handleGetCategoryList = async () => {
    try {
      const response = await getCategoryProgram()
      const dataCategory = response.data
      const remapCategory = dataCategory.map((val) => {
        return {
          value: val.id,
          label: val.name
        }
      })
      setCategoryList(remapCategory)
    } catch (error) {
      console.log(error)
    }
  }

  const handleGetLearningList = async () => {
    setLoading(true)
    try {
      const response = await getProgramList(params)
      setLearningList(response.data)
      setCount(response.count)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    handleGetCategoryList()
  }, [])

  useEffect(() => {
    handleGetLearningList()
    // eslint-disable-next-line
  }, [params])

  const handleSearchChange = (e) => {
    setParams({ ...params, [e.target.name]: e.target.value })
  }

  const handleChangeOrder = (e) => {
    setParams({ ...params, 'order': e })
  }

  const handleChangeCategory = (e) => {
    setParams({ ...params, 'eventCategory': e })
  }

  const handlePage = (params) => {
    if (learningList.length < count) {
      setParams({ ...params, 'take': params.take + 8 })
    }
  }

  const loadingData = (number) => Array.from({ length: number }, (_, i) => {
    return (
      <Grid.Col span={{base: 6, md: 6, lg: 3 }} key={i}>
        <SkeletonCard />
      </Grid.Col>
    )
  })

  const mappingDataLearning = (data) => {
    if (data.length > 0) {
      const remap = data.map((val, index) => {
        return (
          <Grid.Col span={{base: 6, md: 6, lg: 3 }} key={index}>
            <ProgramCard data={val} />
          </Grid.Col>
        )
      })
      return remap
    } else {
      return (
        <Grid.Col span={12}>
          <Box ta='center' py={60}>
            <Text c="dark">Course yang anda cari belum tersedia</Text>
          </Box>
        </Grid.Col>
      )
    }
  }

  return (
    <HelmetProvider>
      <SEOPage title='Online Course - Laju Reaksi by Katalis Talenta Indonesia' />
      <PublicLayout>
        <Box className={classes.trainingSection}>
          <Container fluid className={classes.section}>
            <Box>
              <Box className={classes.paddingPage}>
                <Text className={classes.titleHeader} fw="bold">Temukan Course yang ingin kamu hadiri!</Text>
                <Text className={classes.captionHeader}>Program Course dari PT Katalis Talenta Indonesia</Text>
                <Box className={classes.searchTrainingBox}>
                  <Grid>
                    <Grid.Col span={{ base: 12, md: 4,lg: 4 }}>
                      <TextInput
                        placeholder="Cari Course. . ."
                        name="search"
                        onChange={handleSearchChange}
                      />
                    </Grid.Col>
                    <Grid.Col span={{ base: 12, md: 4,lg: 4 }}>
                      <Select
                        name="order"
                        data={defaultOrder}
                        onChange={handleChangeOrder}
                        allowDeselect={false}
                        value={params.order}
                      />
                    </Grid.Col>
                    <Grid.Col span={{ base: 12, md: 4,lg: 4 }}>
                      <Select
                        name="eventCategory"
                        data={categoryList}
                        onChange={handleChangeCategory}
                        value={params.eventCategory}
                        placeholder='Pilih Kategori'
                        clearable
                      />
                    </Grid.Col>
                  </Grid>
                </Box>
              </Box>
              <Box mt="xl">
                <Grid gutter='xl' className={classes.gridProgramCard} align="center">
                  {loading ? loadingData(8) : mappingDataLearning(learningList)}
                </Grid>
              </Box>
              <Box mt={30} align="center">
                {learningList.length < count ? (<Button loading={loading} color='red' onClick={() => handlePage(params)}>Selengkapnya</Button>) : ''}
              </Box>
            </Box>
          </Container>
        </Box>
      </PublicLayout>
    </HelmetProvider>
  )
}

export default LearningVideo