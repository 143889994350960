import Home from "./pages/Home"
import Training from "./pages/Training"
import TrainingSlug from "./pages/Training/slug/TrainingSlug"
import Bootcamp from "./pages/Bootcamp"
import BootcampSlug from "./pages/Bootcamp/slug/BootcampSlug"
import LearningVideo from "./pages/LearningVideo"
import LearningSlug from "./pages/LearningVideo/slug/LearningSlug"
import About from "./pages/About"
import Login from "./pages/Login"
import Register from "./pages/Register"
import Checkout from "./pages/Checkout"
import ResetPassword from "./pages/ResetPassword"
import VerifiedPassword from './pages/VerifiedPassword'
import VerifiedMember from "./pages/VerifiedMember"
import Blog from "./pages/Blog"
import DetailBlog from './pages/Blog/Slug/DetailBlog'
import TermsCondition from "./pages/TermsCondition"
import PrivacyPolicy from "./pages/PrivacyPolicy"
import ValidateCertificate from "./pages/ValidateCertificate"

// member router
import Dashboard from "./pages/Member/Dashboard"
import Program from './pages/Member/Program'
import DetailProgram from "./pages/Member/Program/slug/DetailProgram"
import Transaction from "./pages/Member/Transaction"
import DetailTransaction from "./pages/Member/Transaction/slug/DetailTransaction"
import Profile from "./pages/Member/Profile"

import { IconGauge, IconBook, IconUserCog, IconCoin } from "@tabler/icons-react"

const routes = [
  {
    name: 'landing-page',
    route: '/',
    hasChildren: false,
    component: <Home />
  },
  {
    name: 'training',
    route: '/training',
    hasChildren: true,
    children: [
      {
        name: 'index',
        route: null,
        component: <Training />
      },
      {
        name: 'slug',
        route: ':slug',
        component: <TrainingSlug />
      },
    ]
  },
  {
    name: 'e-learning',
    route: '/course',
    hasChildren: true,
    children: [
      {
        name: 'index',
        route: null,
        component: <LearningVideo />
      },
      {
        name: 'slug',
        route: ':slug',
        component: <LearningSlug />
      },
    ]
  },
  {
    name: 'bootcamp',
    route: '/bootcamp',
    hasChildren: true,
    children: [
      {
        name: 'index',
        route: null,
        component: <Bootcamp />
      },
      {
        name: 'slug',
        route: ':slug',
        component: <BootcampSlug />
      },
    ]
  },
  {
    name: 'about',
    route: '/about',
    hasChildren: false,
    component: <About />
  },
  {
    name: 'login',
    route: '/login',
    hasChildren: false,
    component: <Login />
  },
  {
    name: 'register',
    route: '/register',
    hasChildren: false,
    component: <Register />
  },
  {
    name: 'checkout',
    route: '/checkout',
    hasChildren: false,
    component: <Checkout />
  },
  {
    name: 'forgot-password',
    route: '/password-resets',
    hasChildren: false,
    component: <ResetPassword />
  },
  {
    name: 'forgot-password-verify',
    route: '/password-resets/verify',
    hasChildren: false,
    component: <VerifiedPassword />
  },
  {
    name: 'member-verify',
    route: '/auth/verify',
    hasChildren: false,
    component: <VerifiedMember />
  },
  {
    name: 'blog',
    route: '/blog',
    hasChildren: true,
    children: [
      {
        name: 'index',
        route: null,
        component: <Blog />
      },
      {
        name: 'slug',
        route: ':slug',
        component: <DetailBlog />
      },
    ]
  },
  {
    name: 'terms and condition',
    route: '/terms-and-condition',
    hasChildren: false,
    component: <TermsCondition />
  },
  {
    name: 'privacy policy',
    route: '/privacy-policy',
    hasChildren: false,
    component: <PrivacyPolicy />
  },
  {
    name: 'validate certificate',
    route: '/validation-certificate',
    hasChildren: false,
    component: <ValidateCertificate />
  },
]

const memberRouter = [
  {
    name: 'dashboard',
    route: '/dashboard',
    hasChildren: false,
    component: <Dashboard />,
    icon: IconGauge
  },
  {
    name: 'program',
    route: '/program',
    hasChildren: true,
    children: [
      {
        name: 'index',
        route: null,
        component: <Program />
      },
      {
        name: 'EventDetail',
        route: ':id',
        component: <DetailProgram />
      },
    ],
    icon: IconBook
  },
  {
    name: 'transaction',
    route: '/transaction',
    hasChildren: true,
    children: [
      {
        name: 'index',
        route: null,
        component: <Transaction />
      },
      {
        name: 'transactionDetail',
        route: ':invoice',
        component: <DetailTransaction />
      },
    ],
    component: <Transaction />,
    icon: IconCoin
  },
  {
    name: 'profile',
    route: '/profile',
    hasChildren: false,
    component: <Profile />,
    icon: IconUserCog
  },
]

export { routes, memberRouter }