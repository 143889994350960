import React from 'react'
import PublicLayout from '../../layouts/PublicLayout'
import { Box } from '@mantine/core'
import BlogHeader from '../../components/pages/Blog/BlogHeader'
import RecentBlogSection from '../../components/pages/Blog/RecentBlogSection'
import AllBlogSection from '../../components/pages/Blog/AllBlogSection'
import { HelmetProvider } from 'react-helmet-async'

const Blog = () => {
  return (
    <HelmetProvider>
      <PublicLayout>
        <BlogHeader />
        <Box>
          <RecentBlogSection />
          <AllBlogSection />
        </Box>
      </PublicLayout>
    </HelmetProvider>
  )
}

export default Blog