import React, { useEffect, useState } from 'react'
import PublicLayout from '../../../layouts/PublicLayout'
import { getBlogs, getDetailBlog } from '../../../services/blog'
import classes from '../Blog.module.css'
import { useParams, useNavigate } from 'react-router-dom'
import { Container, Box, Text, Skeleton, Badge, Flex, Grid, Image } from '@mantine/core'
import { getImageFile } from '../../../services/fileImage'
import dayjs from 'dayjs'
import { IconCalendarEvent } from '@tabler/icons-react'
import { HelmetProvider } from 'react-helmet-async'
import SEOPage from '../../../components/seo/SEOPage'



const DetailBlog = () => {
  const navigate = useNavigate()
  const params = useParams()
  const blogSlug = params.slug
  const [loading, setLoading] = useState(true)
  const [loadingRelated, setLoadingRelated] = useState(true)
  const [relatedBlog, setRelatedBlog] = useState(null)
  const [detailBlog, setDetailBlog] = useState(null)

  const handleGetDetailBlog = async () => {
    setLoading(true)
    try {
      const response = await getDetailBlog(blogSlug)
      setDetailBlog(response)
      handleGetRelatedBlog(response.tags[0].name)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const handleGetRelatedBlog = async (paramsCategory) => {
    setLoadingRelated(true)
    const params = {
      category: paramsCategory,
      take: 5
    }
    try {
      const response = await getBlogs(params)
      setRelatedBlog(response.data)
    } catch (error) {
      console.log(error)
    } finally {
      setLoadingRelated(false)
    }
  }

  useEffect(() => {
    handleGetDetailBlog()
    //eslint-disable-next-line
  }, [blogSlug])

  const loadingDetail = () => {
    return (
      <Box mt={60}>
        <Skeleton height={20} radius='md' mb={12} style={{ width: '12%' }} />
        <Skeleton height={50} radius='md' mb={40} />
        <Skeleton height={500} radius='md' mb={60} />
        <Skeleton height={16} radius='md' mb={10} />
        <Skeleton height={16} radius='md' mb={10} />
        <Skeleton height={16} radius='md' mb={10} />
        <Skeleton height={16} radius='md' mb={10} />
        <Skeleton height={16} radius='md' mb={10} />
        <Skeleton height={16} radius='md' mb={10} />
        <Skeleton height={16} radius='md' mb={10} />
        <Skeleton height={16} radius='md' mb={10} />
        <Skeleton height={16} radius='md' mb={10} />
      </Box>
    )
  }

  const handleToDetailBlog = (slug) => {
    navigate(`/blog/${slug}`)
    handleGetDetailBlog()
  }

  const mappingCategory = (tags) => tags.map((val, index) => {
    return (
      <Badge color='red' variant='filled' key={index}>{val.name}</Badge>
    )
  })

  const loadDetailBlog = (detail) => {
    const innerHtml = { __html: detail.content }
    return (
      <Box mt={60}>
        <SEOPage title={detail.title} />
        <Flex gap='xs' mb={12}>
          {mappingCategory(detail.tags)}
        </Flex>
        <Text className={classes.titleBlog} mb={40}>{detail.title} </Text>
        <img className={classes.posterBlog} src={getImageFile(detail.thumbnail)} alt={detail.title} />
        <Text><Box dangerouslySetInnerHTML={innerHtml} /></Text>
      </Box>
    )
  }

  const loadingRelatedBlog = (number) => Array.from({ length: number }, (_, i) => {
    return (
      <Box key={i} mt={20} mb={24}>
        <Grid>
          <Grid.Col span={5}>
            <Skeleton h={110} />
          </Grid.Col>
          <Grid.Col span={7}>
            <Skeleton height={24} mb={12} />
            <Skeleton height={12} mb={7} />
            <Skeleton height={12} mb={7} />
            <Skeleton height={12} mb={7} />
            <Skeleton height={12} mb={12} />
          </Grid.Col>
        </Grid>
      </Box>
    )
  })

  const loadRelatedBlog = (relatedBlog) => relatedBlog.map((val, index) => {
    return (
      <Box key={index} mt={20} mb={24} onClick={() => handleToDetailBlog(val.slug)} style={{ cursor: 'pointer' }}>
        <Grid>
          <Grid.Col span={5}>
            <Image height={110} radius="lg" fit='cover' src={val.thumbnail !== null ? getImageFile(val.thumbnail) : null} alt={val.title} withPlaceholder />
          </Grid.Col>
          <Grid.Col span={7}>
            <Text fz="sm" mb={2} fw="bold" lineClamp={1}>{val.title}</Text>
            <Box h={40} mb={10}>
              <Text fz='xs' lineClamp={2} c="dimmed">{val.caption}</Text>
            </Box>
            <Flex align="center">
              <IconCalendarEvent size="0.875rem" />
              <Text fz='xs' color="dimmed" ml={4}>{dayjs(val.createdAt).format('DD MMM YYYY')}</Text>
            </Flex>
          </Grid.Col>
        </Grid>
      </Box>
    )
  }) 


  return (
    <HelmetProvider>
      <PublicLayout>
        <Box className={classes.blogSection}>
          <Container fluid className={classes.section}>
            <Box className={classes.paddingPage}>
              <Grid gutter="xl">
                <Grid.Col span={{ base: 12, lg: 8 }}>
                  {loading ? loadingDetail() : loadDetailBlog(detailBlog)}
                </Grid.Col>
                <Grid.Col span={{ base: 12, lg: 4 }}>
                  <Box mt={60}>
                    <Text className={classes.titleBlog} mb={10}>Blog Terkait </Text>
                    {loadingRelated ? loadingRelatedBlog(5) : loadRelatedBlog(relatedBlog)}
                  </Box>
                </Grid.Col>
              </Grid>
            </Box>
          </Container>
        </Box>
      </PublicLayout>
    </HelmetProvider>
    
  )
}

export default DetailBlog