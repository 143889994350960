import { Box, Card, Group, Text, Badge, Flex, Image, Button, Rating } from '@mantine/core'
import React from 'react'
import classes from './ProgramCard.module.css'
import NoImage from '../../../assets/img/no-image.png'
import { getImageFile } from '../../../services/fileImage'
import { useNavigate } from 'react-router-dom'
import { formatPrice } from '../../../plugins/formatPrice'
import { IconMap, IconCalendarEvent, IconVideo, IconClock2 } from '@tabler/icons-react'
import dayjs from 'dayjs'

const ProgramCard = ({ data }) => {
  const navigate = useNavigate()

  const formatTime = (time) => {
    return time.slice(0, -3)
  }

  const mappingSchedule = (type, latestSchedule) => {
    
    const mappingTypeSchedule = () => {
      if (latestSchedule !== null) {
        if (type === 'offline') {
          return (
            <Group gap='xs' mb={4} wrap='nowrap'>
              <IconMap size='1rem' stroke='1.5' color='red' />
              <Text className={classes.titleSchedule} lineClamp={1}>{latestSchedule.address ? latestSchedule.address : '-'}</Text>
            </Group>
          )
        } else if (type === 'online') {
          const mappingTypeOnline = latestSchedule.onlineLink
          const mappingPlatformOnline =  (val) => {
            if (val.includes('zoom')) {
              return 'Zoom'
            } else if (val.includes('teams')) {
              return 'Microsoft Teams'
            } else if (val.includes('meet.google')) {
              return 'Google Meet'
            }
          }
          return (
            <Group gap='xs' mb={4} wrap='nowrap'>
              <IconVideo size='1rem' stroke='1.5' color='red' />
              <Text className={classes.titleSchedule} lineClamp={1}>{mappingPlatformOnline(mappingTypeOnline)}</Text>
            </Group>
          )
        }
      } else {
        return (
          <Group gap='xs' mb={4}>
            <IconVideo size='1rem' stroke='1.5' color='red' />
            <Text className={classes.titleSchedule} lineClamp={1}>Belum Tersedia</Text>
          </Group>
        )
      }
    }

    return (
      <Box className={classes.metaLocation}>
        <Group gap='xs' mb={4}>
          <IconCalendarEvent size='1rem' stroke='1.5' color='red' />
          <Text className={classes.titleSchedule} lineClamp={1}>{latestSchedule !== null ? dayjs(latestSchedule.eventStart).format('DD MMMM YYYY') : 'Belum Tersedia'}</Text>
        </Group>
        <Group gap='xs' mb={4}>
          <IconClock2 size='1rem' stroke='1.5' color='red' />
          <Text className={classes.titleSchedule} lineClamp={1}>{latestSchedule !== null ? `${formatTime(latestSchedule.timeStart)} - ${formatTime(latestSchedule.timeEnd)}` : 'Belum Tersedia'}</Text>
        </Group>
        {mappingTypeSchedule()}
      </Box>
    )
  }

  return (
    <Card className={classes.programCard} shadow='sm' radius='md' withBorder onClick={() => navigate(`/${data.kind}/${data.slug}`)}>
      <Card.Section>
        <Image src={data.eventImages.length === 0 ? NoImage : getImageFile(data.eventImages[0].filepath)} />
      </Card.Section>
      <Box className={classes.contentProgram}>
        <Flex justify='space-between' mt={2}>
          <Badge color="indigo.6" variant="filled" size='xs' mb='xs' className={classes.typeProgram}>
            {data.eventCategory.name}
          </Badge>
          {data.userRating !== null ? (<Rating size='xs' defaultValue={data.userRating} fractions={5} readOnly />) : ''}
        </Flex>
        <Badge color="orange.6" variant="filled" size='xs' mb='xs' className={classes.typeProgram}>
          {data.type}
        </Badge>
        <Box>
          <Text className={classes.titleProgram} lineClamp={2}>{data.name}</Text>
          
        </Box>
        {mappingSchedule(data.type, data.latestSchedule)}
        <Box my={12}>
          <Flex justify="end" align="center" className={classes.textLineThrought} h={20}>
            <Text className={classes.blinkPrice} c="red">{data.latestSchedule !== null && data.latestSchedule.price < data.price ? formatPrice(data.price): ''}</Text>
          </Flex>
          <Flex justify={data.latestSchedule !== null ? 'space-between' : 'end'} align="center">
            <Text className={classes.discountText} c="red">{data.latestSchedule !== null && data.latestSchedule.price < data.price ? `${((data.price - data.latestSchedule.price) / data.price * 100).toFixed(0)}%` : ''}</Text>
            <Text className={classes.priceProgram} c="red">{data.latestSchedule !== null ? formatPrice(data.latestSchedule.price): 'Belum Tersedia'}</Text>
          </Flex>
        </Box>
        <Box>
          <Button className={classes.buttonProgram} fullWidth color={data.latestSchedule !== null ? data.latestSchedule.status === 'registration_open' ? 'indigo.9' : 'red.9' : 'red.9'}>{ data.latestSchedule !== null ? data.latestSchedule.status === 'registration_open' ? 'Daftar Sekarang' : 'Kelas Ditutup' : 'Kelas Belum Tersedia' }</Button>
        </Box>
      </Box>
    </Card>
  )
}

export default ProgramCard