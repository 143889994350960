import React from 'react'
import classes from './PublicFooter.module.css'
import { Box, Container, Divider, Grid, Text, Flex, Image, ActionIcon, Group } from '@mantine/core'
import { useNavigate } from 'react-router-dom'
import KatalisLogo from '../../../assets/img/KatalisLogo.svg'
import { IconBrandInstagram, IconBrandLinkedin } from '@tabler/icons-react'


const PublicFooter = () => {
  const navigate = useNavigate()

  const toRoutePage = (page) => {
    return navigate(page)
  }

  const openLink = (link) => {
    window.open(`${link}`, '_blank')
  }
  return (
    <Box className={classes.defaultSection}>
      <Container fluid className={classes.section}>
        <Divider size="md" />
        <Grid py={24}>
          <Grid.Col span={{ base: 12, lg: 8 }} order={2}>
            <Grid>
              <Grid.Col span={{ base:6, sm: 6, md: 6, lg: 2 }} offset={{ base: 0, sm: 0, md: 0, lg: 6 }}>
                <Text fw="bold">Menu</Text>
                <Box mt={6}>
                  <Text className={classes.routeLink} fz={13} mb={4} onClick={() => toRoutePage('/training')}>Training</Text>
                  <Text className={classes.routeLink} fz={13} mb={4} onClick={() => toRoutePage('/course')}>Course</Text>
                  <Text className={classes.routeLink} fz={13} mb={4} onClick={() => toRoutePage('/bootcamp')}>Bootcamp</Text>
                </Box>
              </Grid.Col>
              <Grid.Col span={{ base:6, sm: 6, md: 6, lg: 2 }}>
                <Text fw="bold">About</Text>
                <Box mt={6}>
                  <Text className={classes.routeLink} fz={13} mb={4} onClick={() => toRoutePage('/about')}>About Us</Text>
                  <Text className={classes.routeLink} fz={13} mb={4} onClick={() => toRoutePage('/blog')}>Blog</Text>
                </Box>
              </Grid.Col>
              <Grid.Col span={{ base:6, sm: 6, md: 6, lg: 2 }}>
                <Text fw="bold">Bantuan</Text>
                <Box mt={6}>
                  <Text className={classes.routeLink} fz={13} mb={4} onClick={() => toRoutePage('/faq')}>FAQ</Text>
                  <Text className={classes.routeLink} fz={13} mb={4} onClick={() => toRoutePage('/terms-and-condition')}>Syarat & Ketentuan</Text>
                  <Text className={classes.routeLink} fz={13} mb={4} onClick={() => toRoutePage('/privacy-policy')}>Kebijakan Privasi</Text>
                  <Text className={classes.routeLink} fz={13} mb={4} onClick={() => toRoutePage('/validation-certificate')}>Validasi Sertifikat</Text>
                </Box>
              </Grid.Col>
            </Grid>
          </Grid.Col>
          <Grid.Col span={{ base: 12, lg: 4 }} order={1} mb={30}>
            <Flex align="center">
              <Image mr={24} src={KatalisLogo} width={100} />
              <Box>
                <Text fz="md" fw="bold">Laju Reaksi</Text>
                <Text fz={13}>Setiap langkah dalam platform kami dirancang dengan cermat untuk menggugah rasa tanggung jawab dan komitmen untuk memperbaiki permasalahan lingkungan. Ini bukan sekadar pembelajaran, tetapi serangkaian pengalaman yang membentuk sikap dan perilaku menuju gaya hidup yang berkelanjutan.</Text>
              </Box>
            </Flex>
          </Grid.Col>
        </Grid>
        <Divider size="md" />
        <Flex justify="space-between" align="center" mt="md">
          <Text c='yellow' fw='bold'> PT Katalis Talenta Indonesia</Text>
          <Group spacing={0} position='center' noWrap>
            <ActionIcon color="yellow" size="xl" radius="xl" variant="light" onClick={() => openLink('https://www.linkedin.com/company/katalistalenta?_l=en_US')}>
              <IconBrandLinkedin size="1.5rem" stroke={1.5} />
            </ActionIcon>
            <ActionIcon color="yellow" size="xl" radius="xl" variant="light"  onClick={() => openLink('https://www.instagram.com/katalistalenta/')}>
              <IconBrandInstagram size="1.5rem" stroke={1.5} />
            </ActionIcon>
          </Group>
        </Flex>
      </Container>
    </Box>
  )
}

export default PublicFooter